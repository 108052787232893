<template>
  <a
    href="https://www.lada.ru/configurator?family=vesta"
    target="_blank"
    rel="noopener noreferrer"
    class="btn-primary button-configurator"
    @click="onClick"
  >
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M9.12446 21H12.8762C13.1111 20.9998 13.3388 20.9218 13.5218 20.7788C13.7047 20.6359 13.8319 20.4366 13.8821 20.214L14.3078 18.335C14.8671 18.0975 15.3974 17.8003 15.8889 17.449L17.7823 18.034C18.0064 18.1033 18.2482 18.0962 18.4676 18.0138C18.6869 17.9314 18.8706 17.7787 18.9882 17.581L20.864 14.428C21.1032 14.021 21.0207 13.507 20.6651 13.189L19.1964 11.889C19.2632 11.3001 19.2632 10.7059 19.1964 10.117L20.6651 8.817C21.0207 8.5 21.1032 7.985 20.864 7.578L18.9882 4.425C18.8707 4.22753 18.6871 4.07495 18.468 3.99256C18.2489 3.91017 18.0073 3.90292 17.7833 3.972L15.8889 4.557C15.3961 4.20668 14.8657 3.90927 14.3068 3.67L13.8821 1.787C13.8321 1.5642 13.705 1.36469 13.5221 1.22155C13.3391 1.07841 13.1112 1.00024 12.8762 1H9.12446C8.88942 1.00024 8.66153 1.07841 8.47857 1.22155C8.29561 1.36469 8.16856 1.5642 8.1185 1.787L7.69798 3.669C7.42381 3.784 7.15583 3.913 6.89507 4.055C6.62503 4.207 6.36323 4.375 6.11174 4.555L4.21836 3.97C3.99425 3.90066 3.75243 3.90778 3.53307 3.99019C3.31371 4.07259 3.13 4.22532 3.01245 4.423L1.13659 7.576C1.02001 7.77397 0.976196 8.00449 1.01234 8.22978C1.04848 8.45507 1.16243 8.66177 1.33551 8.816L2.80425 10.113C2.73746 10.7019 2.73746 11.2961 2.80425 11.885L1.33551 13.185C1.16267 13.3392 1.04888 13.5457 1.01274 13.7708C0.976607 13.9958 1.02027 14.2261 1.13659 14.424L3.01657 17.575C3.13412 17.7727 3.31783 17.9254 3.5372 18.0078C3.75656 18.0902 3.99837 18.0973 4.22248 18.028L6.11586 17.443C6.60775 17.7946 7.13837 18.0921 7.69798 18.33L8.11747 20.214C8.16777 20.4368 8.29506 20.6362 8.47821 20.7792C8.66137 20.9221 8.88939 21 9.12446 21Z"
        stroke="white"
        stroke-width="2"
      />
      <circle cx="11" cy="11" r="3" stroke="white" stroke-width="2" />
    </svg>
    Конфигуратор
  </a>
</template>

<script>
import { gtmConfigurator } from "@/utils/gtm";

export default {
  name: "ButtonConfigurator",
  methods: {
    onClick() {
      gtmConfigurator();
    },
  },
};
</script>
